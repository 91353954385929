<template>
  <div class="drag-correct-pic-game-container layout-border">
    <div class="drag-correct-pic-game-box">
      <Star :totalStarNumber="starNum" :currentIndex="steps" />
      <div class="background">
        <div class="drag-correct-pic-game-content">
          <div class="game-top-box">
            <div class="game-aside-content">
              <div
                v-for="(item, index) in optionsList"
                :key="index"
                class="item-content"
                :class="{
                  yellowBg: item.done,
                  shake: isClicked && index === dropIndex - 1,
                }"
                draggable="false"
                @drop.stop="drop(item)"
                @dragover.stop="allowDrop($event)"
                :style="[item.done? {backgroundImage:`url(${item.bgImg})`}:'']"
              >
                <span v-if="!item.done" class="pinyin font-pinyin-medium">{{
                  item.pinyin
                }}</span>
                <span v-if="!item.done" class="font-hanzi-medium">{{
                  item.element
                }}</span>
                <img v-if="item.done" :src="item.bgImg" class="bg-img" />
              </div>
            </div>
          </div>
        </div>

        <div class="answer-area">
          <div class="redBackground"></div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in answerList"
              :key="index"
              class="content-words-two"
              draggable="true"
              @dragstart.stop="drag(item)"
              @dragend.stop="handleDropBg"
              v-show="item.isShow"
              :class="{ notShow: currentKey === item.id }"
            >
              <img v-if="item.image" :src="item.image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="steps"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
  </div>
</template>
<script>
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  components: {
    PageButton,
    Star,
  },
  props: {
    starNum: {
      type: Number,
      default: 0,
    },
    bgImage: {
      type: String,
      default: "",
    },
    answerList: {
      type: Array,
      require: true,
      default: () => [],
    },
    optionsList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bottomList: {
      type: Array,
      require: true,
      default: () => [],
    },
    titleInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  data() {
    return {
      steps: 0,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      isLastStep: false,
      dropIndex: null,
      isClicked: false,

      dragSocketInfo: {},
      dropSocketInfo: {},
      currentKey: 0,
    };
  },
  mounted() {
    this.$bus.$on("dragCorrectPicGame", ({ item }) => {
      this.drag(item, true);
    });
    this.$bus.$on("dropCorrectPicGame", ({ item }) => {
      this.drop(item, true);
    });
  },
  watch: {
    dragSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003701,
          data: { value },
          text: "DragCorrectPicGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dropSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2003702,
          data: { value },
          text: "dragCorrectPicGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    updateNumbers(value) {
      this.numBers = value;
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    foundAnswer(item) {
      console.log(item.id);
      this.dropIndex = item.id;
      console.log(this.dropIndex);
      if (item.id === this.currentKey) {
        this.answersfound++;
        this.steps++;
        playCorrectSound(true, false);
        this.$emit("changeAnswerListStatus", item.id);
        this.$emit("changeOptionsListStatus", item.id);
        if (this.steps === this.answerList.length) {
          this.isLastStep = true;
          playCorrectSound();
          startConfetti();
        }
      } else {
        playCorrectSound(false);
        this.isClicked = true;
        setTimeout(() => {
          this.isClicked = false;
          this.currentKey = 0;
        }, 1000);
      }
    },
    drag(item, isFromSocket = false) {
      this.currentKey = item.id;
      if (!isFromSocket) {
        this.dragSocketInfo = {
          item,
          value: Math.random(),
        };
      }
    },
    drop(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      this.foundAnswer(item);
    },
    handleDropBg() {
      this.currentKey = 0;
    },
    allowDrop(event) {
      event.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.drag-correct-pic-game-content {
  position: relative;
  width: -moz-fit-content;
  width: 100%;
  height: 80%;
  justify-content: center;
  z-index: 0;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .game-top-box {
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    .game-aside-content {
      width: 90%;
      height: 70%;
      display: flex;
      flex-wrap: wrap;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      justify-content: space-around;
      align-items: flex-end;
    }
  }
}

.item-content {
  box-sizing: border-box;
  width: 18%;
  height: 40%;
  z-index: 1;
  border-radius: 15px;
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  .bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.yellowBg {
  background-color: #f4d737;
  color: #224e96;
  border-bottom: 8px solid #d1b726;
  border-top: 8px solid #fff2a8;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  img {
    visibility: hidden;
  }
}
// .item-content:active {
//   transform: scale(0.8);
// }

.answer-area {
  bottom: 0;
  width: 65%;
  height: 170px;
  position: absolute;
  display: flex;
  justify-content: center;
  // left: 25%;
  right:20%;
  padding: 10px;
  .redBackground {
    width: 120%;
    height: 50%;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #d87065;
    position: absolute;
    bottom: 0;
  }
  .bottonsWrapper {
    display: flex;
    bottom: 0;
    height: -moz-fit-content;
    justify-content: space-between;
    // height: 100%;
    width: 100%;
    position: absolute;
    padding: 20px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #ffffff;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
  }
}
.bgcolor {
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
}
.bgcolor:hover {
  background-color: #912016;
  border-top: 8px solid #6e0c03;
}
.content-words-two {
  max-width: 16%;
  min-width: 21%;
  min-height: 100px;
  z-index: 1;
  border-radius: 15px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
  img {
    max-width: 100%;
    -webkit-user-drag: auto !important;
  }
}
.notShow {
  opacity: 0;
}
.content-words-two:active {
  transform: scale(0.95);
}
.content-words-two:hover {
  opacity: .8;
  border-radius: 15px;
  border-bottom: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}

.drag-correct-pic-game-box {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.blueBg {
  background: #f7f4e0 !important;
}
.background {
  text-align: -webkit-center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #f9f6e6;
  border-radius: 48px;
  margin: auto;
  overflow: hidden;
  .bgIamge {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
