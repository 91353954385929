var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drag-correct-pic-game-container layout-border" },
    [
      _c(
        "div",
        { staticClass: "drag-correct-pic-game-box" },
        [
          _c("Star", {
            attrs: { totalStarNumber: _vm.starNum, currentIndex: _vm.steps },
          }),
          _c("div", { staticClass: "background" }, [
            _c("div", { staticClass: "drag-correct-pic-game-content" }, [
              _c("div", { staticClass: "game-top-box" }, [
                _c(
                  "div",
                  { staticClass: "game-aside-content" },
                  _vm._l(_vm.optionsList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "item-content",
                        class: {
                          yellowBg: item.done,
                          shake: _vm.isClicked && index === _vm.dropIndex - 1,
                        },
                        style: [
                          item.done
                            ? { backgroundImage: `url(${item.bgImg})` }
                            : "",
                        ],
                        attrs: { draggable: "false" },
                        on: {
                          drop: function ($event) {
                            $event.stopPropagation()
                            return _vm.drop(item)
                          },
                          dragover: function ($event) {
                            $event.stopPropagation()
                            return _vm.allowDrop($event)
                          },
                        },
                      },
                      [
                        !item.done
                          ? _c(
                              "span",
                              { staticClass: "pinyin font-pinyin-medium" },
                              [_vm._v(_vm._s(item.pinyin))]
                            )
                          : _vm._e(),
                        !item.done
                          ? _c("span", { staticClass: "font-hanzi-medium" }, [
                              _vm._v(_vm._s(item.element)),
                            ])
                          : _vm._e(),
                        item.done
                          ? _c("img", {
                              staticClass: "bg-img",
                              attrs: { src: item.bgImg },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _c("div", { staticClass: "answer-area" }, [
              _c("div", { staticClass: "redBackground" }),
              _c(
                "div",
                { staticClass: "bottonsWrapper" },
                _vm._l(_vm.answerList, function (item, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.isShow,
                          expression: "item.isShow",
                        },
                      ],
                      key: index,
                      staticClass: "content-words-two",
                      class: { notShow: _vm.currentKey === item.id },
                      attrs: { draggable: "true" },
                      on: {
                        dragstart: function ($event) {
                          $event.stopPropagation()
                          return _vm.drag(item)
                        },
                        dragend: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleDropBg.apply(null, arguments)
                        },
                      },
                    },
                    [
                      item.image
                        ? _c("img", { attrs: { src: item.image, alt: "" } })
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          numBers: _vm.steps,
          isLastStep: _vm.isLastStep,
        },
        on: {
          clickNext: _vm.nextPage,
          updateNumbers: function ($event) {
            return _vm.updateNumbers($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }